@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.carousel-three-column-container {
  margin: 12px;
  display: flex;
  flex-direction: column;

  .story-header-container {
    display: flex;
    flex-direction: row;
    @media (min-width: 768px) and (max-width: 940px) {
      margin: 0px 15px;
    }
    .story-header {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;
    }

    .navigation-btn {
      display: flex;
      gap: 8px;
      margin-left: auto;

      .slide-count {
        padding: 2px;
      }

      button {
        padding: 0%;
        border-radius: 22px;
        width: 30px;
        height: 30px;
        border: 1px solid $pale-grey;
        color: $color-darkgray;
      }
    }
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;

    .card-image {
      display: flex;

      img {
        width: 100%;
        height: 200px;
        //object-fit: fill;
        border-radius: 14px;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding: 0px 24px;

      .card-content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .publication-details > span {
          margin: 8px 0px;
        }

        .publication-details {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;

          .icon-container {
            padding: 0px 5px 0px 0px;
            margin: 0px !important;
            .icon {
              height: 16px;
              width: 16px;
            }
          }

          .publication-details-text {
            font-family: $font-heading;
            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: $mpg-color-gray-50;
            padding-left: 5px;
            @include mobile {
              font-size: 10px;
              line-height: 13px;
            }
          }

          .topic {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 20ch;
            @include mobile {
              display: inline-block;
              max-width: 18ch;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            @media (width: 768px) {
              max-width: 10ch;
            }
          }
        }
      }
    }

    .story-text {
      display: flex;
      flex-grow: 1;
      .story-title {
        color: $color-darkgray;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        @include mobile {
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-width: fit-content;
          line-height: 27px;
        }
      }
    }

    .cta-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-top: 25px;

      .cta-link {
        font-family: $font-heading;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        color: $mpg-color-orange-55;
        text-decoration: none;
        text-align: left;
        display: flex;
        align-items: center;

        @include mobile {
          font-size: 17px;
          line-height: 20px;
        }

        &:hover {
          color: $mpg-color-orange-100;
        }
      }

      .icon-container {
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;
        }

        .cta-icon {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin-left: 5px;
          margin-top: 6px;
        }
      }
    }
  }

  .no-carousel-data {
    text-align: center;
    padding: 15px;
    background: $pale-grey;
    border-radius: 10px;
  }

  .carousel-container {
    margin-bottom: 10px;
    @media (min-width: 768px) and (max-width: 940px) {
      margin: 0px 15px;
    }
  }
}

@media (max-width: 767px) {
  .carousel-three-column-container {
    padding: 10px 24px !important;
    margin: 12px 0px 0px 0px;
    .story-header-container {
      .navigation-btn {
        display: none;
      }
    }
    .card-container {
      flex-direction: column;

      .card-body {
        padding: 0px;
      }
      .card-image {
        img {
          width: 100%;
          height: 140px;
          object-fit: fill;
        }
      }
    }
    .carousel-item {
      border-radius: 14px;
      padding: 16px;
      border: 1px solid $mpg-color-gray-25;
      background: $white;
    }

    .story-text {
      min-height: 110px;
    }

    .slick-slide {
      width: 278px;
      @include mobile-small {
        width: 250px;
      }
      // @include mobile-small{
      //     width: 240px;
      // }

      // @include mobile{
      //     width: 260px;
      // }

      // @include mobile-plus{
      //     width: 280px;
      // }

      // @include mobile-large{
      //     width: 300px;
      // }
    }

    .card-cover {
      margin-right: 20px;
      border: 1px solid $pale-grey;
      border-radius: 14px;
      padding: 15px;
      background: $white;
    }
    .slick-slide {
      margin-right: 11px !important;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
