@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/mixins';

.next-story-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 28px;
  background: $mpg-color-gray-25;
  width: 100%;
  padding: 24px;
  margin-top: 20px; 

  .story-header {
    color: $mpg-color-gray-70;
    font-family: $font-heading;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    padding: 0 12px;
  }

  .story-body {
    display: flex;
    gap: 24px;
    height: 136px;
    padding: 0 12px;
    .story-image {
      width: 240px;
      height: 136px;
      overflow: hidden;
      border-radius: 14px;
      padding:0px;
      .image {
        width: 240px;
        height: 136px;
        border-radius: 14px;
        object-fit: fill;
        object-position: center;
      }
    }

    .story-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding:0px;
      gap:32px;

      .publication-details {
        display: flex;
        align-items: center;
        margin: 0;

        .icon-container {
          padding: 0px 5px 0px 0px;
          .icon {
            height: 16px;
            width: 16px;
          }
        }
        span:not(:first-child):not(:last-child) {
          &:after {
            content: '•';
            color: $mpg-color-gray-50;
            margin: 0 4px;
          }
        }

        .publication-details-text {
          font-family: $font-heading;
          font-size: 12px;
          line-height: 13px;
          font-weight: 400;
          color: $mpg-color-gray-50;
          @include mobile {
            font-size: 10px;
            line-height: 13px;
          }
        }

        .topic {
          @media (max-width: 767px) {
            display: inline-block;
            max-width: 12ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      h5 {
        font-family: $font-heading;
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        color: $mpg-color-gray-100;
        @include mobile {
          font-size: 15px;
          line-height: 20px;
        }
      }

      .cta-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .cta-link {
          font-family: $font-heading;
          font-size: 20px;
          line-height: 20px;
          font-weight: 600;
          color: $mpg-color-orange-55;
          text-decoration: none;
          text-align: left;
          display: flex;
          align-items: center;

          @include mobile {
            font-size: 17px;
            line-height: 20px;
          }

          &:hover {
            color: $mpg-color-orange-100;
          }
        }

        .icon-container {
          display: flex;
          align-items: center;
          transition: transform 1s ease;

          .icon{
            width: 16px;
            height: 16px;
          }
          
          .cta-icon {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }

      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 767px) {
  .next-story-container {
    border-radius: 0px;

    .story-header {
      color: $mpg-color-gray-100;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      text-align: left;
      padding: 0px;
    }
    .story-body {
      padding: 0px;
      .story-image {
        border-radius: 2px;
        width: 112px;
        height: 66px;
        .image {
          width: 112px;
          height: 66px;
          border-radius: 2px;
        }
      }
    }
    .date {
      font-size: 10px;
      line-height: 12px;
    }

    h5 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }

    .story-body {
      flex-direction: row-reverse; // Reverse the order on smaller screens
      gap: 5px;

      .story-image {
        flex: 0 2 41%;
      }

      .story-text {
        flex: 1 1 70%;
        gap: 0px;
      }
    }
  }
}
